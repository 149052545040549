// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const moment = require('moment-timezone');
const inputModule = require('../modules/input');
const helperModule = require('../modules/helper');
const userModule = require('../modules/user');
const externalUserModule = require('../modules/external-user');
const appModule = require('../modules/app');
const Constants = require('../../../Constants');
const { getCookie } = require('../modules/utilities');


/**
 * State
 */

let assetGlobalTimeout;
let assetGlobalAssetId;
let assetGlobalAssetData;
let assetGlobalAssetType;
let assetGlobalItems;
let assetGlobalConfiguration;
let assetGlobalOrganization;
let assetGlobalActionButtonTitle;
let assetGlobalAvailableTypes;
let assetGlobalActivityContext = Constants.VIEW_LOAD_CONTEXTS.CONTEXT_HISTORY;
let assetGlobalShowStockLevelWidget = false;
let assetGlobalShowPurchaseOption = false;
let assetGlobalCheckInOutEnabled = true;
let assetGlobalGeolocationEnabled = false;
let customOwnerField = 'Scanned by';
let assetGlobalIsNew = true;
let assetGlobalActivityAssetType;
let assetGlobalSelectedGroupId;
let availablePersonnel = [];
let assetGroups = [];


/**
 * Check In Out Handlers
 */

const updateCheckInOutStatusForAsset = async (action = 'check_in', assetId, groupId, quantity, personnelId, name) => {

	// Create parameters
	const parameters = action === 'check_in' ? {
		assetId,
		groupId,
		action: 'check_in',
		platform: 'web'
	} : {
		assetId,
		action: 'check_out',
		quantity,
		platform: 'web',
		...personnelId != null ? {
			personnelId
		} : undefined,
		...name != null ? {
			name
		} : undefined
	};

	// Update check in out status
	try {
		const group = await Parse.Cloud.run('updateCheckInOutStatusForAsset', parameters);
		return group;
	} catch (err) {
		return null;
	}
};

const populateCheckInOutDisplay = () => {

	// Get elements
	const checkInOutHolder = document.getElementById('asset-check-in-out-holder');
	const checkInOutContainerView = document.getElementById('asset-check-in-out-container-view');
	const checkInOutActionButton = document.getElementById('asset-check-in-out-action-button');

	// Update visibility
	if (assetGlobalCheckInOutEnabled === true && assetGlobalIsNew === false) {
		checkInOutHolder.style.display = 'block';
	} else {
		checkInOutHolder.style.display = 'none';
		return;
	}
	if (assetGroups.length === 0) {
		checkInOutContainerView.style.display = 'none';
	} else {
		checkInOutContainerView.style.display = 'block';
	}

	// Create checked out rows
	let html = '';
	assetGroups.forEach((group) => {

		// Get group parameters
		const {
			id, quantity, createdAt, personnel
		} = group;

		// Create element
		html += `
		<div class='asset-checked-out-row'>
			<div class='row-left'>
				<div class='check-out-name-detail'>
					<span>${personnel.name}</span>
					<span class='check-out-quantity-detail'>${quantity}</span>
				</div>
				<div class='check-out-date-detail'>
					<p>Checked out ${`${moment(createdAt).format('MMM D, YYYY')} at ${moment(createdAt).format('h:mm a')}`}</p>
				</div>
			</div>
			<div class='row-right'>
				<button data-group-id='${id}' name='asset-check-in-action'>Check In</button>
			</div>
		</div>
		`;
	});
	checkInOutContainerView.innerHTML = html;

	// Set button content
	const title = assetGlobalConfiguration.scan.type_schemas[assetGlobalAssetType].display;
	checkInOutActionButton.innerHTML = `Check Out ${title}`;
};

const handleCheckOut = () => {

	// Get asset type
	const typeString = assetGlobalConfiguration.scan.type_schemas[assetGlobalAssetType].display;

	// Get available quantity
	let availableQuantity = assetGlobalAssetData.quantity;
	assetGroups.forEach((group) => {
		const groupQuantity = group.quantity;
		if (groupQuantity != null) {
			availableQuantity -= groupQuantity;
		}
	});

	// Create confirmation alert
	document.getElementById('asset-check-out-action-modal-title').innerHTML = `Check Out ${typeString}`;
	document.getElementById('asset-check-out-action-modal-description').innerHTML = `Are You Ready To Check Out This ${typeString}?`;
	document.getElementById('asset-check-out-action-modal-remaining-label').innerHTML = availableQuantity === 1 ? '1 copy available to check out' : `${availableQuantity} copies available to check out`;

	// Reset inputs
	document.getElementById('asset-check-out-action-modal-quantity').value = '';
	document.getElementById('asset-check-out-action-modal-personnel').value = '';

	// Set personnel options
	let html = '<option selected value="">Select a Person</option>';
	availablePersonnel.forEach((personnel) => {
		html += `<option value="${personnel.id}">${personnel.name}</option>`;
	});
	document.getElementById('asset-check-out-action-modal-personnel').innerHTML = html;

	// Display confirmation alert
	inputModule.showModalWithId('asset-check-out-action-modal');
};

const handleCheckIn = (groupId) => {

	// Get asset type
	const typeString = assetGlobalConfiguration.scan.type_schemas[assetGlobalAssetType].display;

	// Create confirmation alert
	document.getElementById('asset-check-in-action-modal-title').innerHTML = `Check In ${typeString}`;
	document.getElementById('asset-check-in-action-modal-description').innerHTML = `Are You Ready To Check In This ${typeString}?`;

	// Set state
	assetGlobalSelectedGroupId = groupId;

	// Display confirmation alert
	inputModule.showModalWithId('asset-check-in-action-modal');
};

const performCheckIn = () => {

	// Get asset type
	const typeString = assetGlobalConfiguration.scan.type_schemas[assetGlobalAssetType].display;

	// Hide confirmation alert
	inputModule.hideModalWithId('asset-check-in-action-modal');

	// Update check in out status
	const assetID = assetGlobalAssetData.id;
	if (assetID != null) {
		updateCheckInOutStatusForAsset('check_in', assetID, assetGlobalSelectedGroupId).then((group) => {
			if (group != null && group.id != null) {

				// Remove group from asset data
				assetGroups = assetGroups.filter((groupObj) => groupObj.id !== group.id);
				assetGlobalAssetData.groups = [...assetGroups];

				// Add new activity to asset data
				const activityObj = assetGlobalAssetData.activity;
				if (activityObj != null) {
					const { events } = activityObj;
					if (events != null) {
						events.push(group);
						activityObj.events = events;
						assetGlobalAssetData.activity = activityObj;
					} else {
						assetGlobalAssetData.activity = { events: [group] };
					}
				} else {
					assetGlobalAssetData.activity = { events: [group] };
				}

				// Update display
				populateCheckInOutDisplay();

			} else {

				// Set content
				document.getElementById('asset-check-in-out-error-modal-title').innerHTML = `Check In ${typeString}`;
				document.getElementById('asset-check-in-out-error-modal-description').innerHTML = `We're Having Trouble Checking In Your ${typeString}. Please Try Again.`;

				// Display error modal
				inputModule.showModalWithId('asset-check-in-out-error-modal');
			}
		});
	}
};

const performCheckOut = () => {

	// Get asset type
	const typeString = assetGlobalConfiguration.scan.type_schemas[assetGlobalAssetType].display;

	// Get parameters
	const quantity = document.getElementById('asset-check-out-action-modal-quantity').value;
	const personnelId = document.getElementById('asset-check-out-action-modal-personnel').value;

	// Format parameters
	const quantityNumber = quantity ? parseInt(quantity, 10) : -1;

	// Validate parameters
	if (!quantity || quantityNumber <= 0) {

		// Set content
		document.getElementById('asset-check-in-out-error-modal-title').innerHTML = `Check Out ${typeString}`;
		document.getElementById('asset-check-in-out-error-modal-description').innerHTML = 'Please Enter The Quantity To Check Out.';

		// Display error modal
		inputModule.showModalWithId('asset-check-in-out-error-modal');

		// Return
		return;
	}
	if (!personnelId) {

		// Set content
		document.getElementById('asset-check-in-out-error-modal-title').innerHTML = `Check Out ${typeString}`;
		document.getElementById('asset-check-in-out-error-modal-description').innerHTML = `Please Select A Person To Check Out This ${typeString}`;

		// Display error modal
		inputModule.showModalWithId('asset-check-in-out-error-modal');

		// Return
		return;
	}

	// Update display
	document.getElementById('asset-check-out-action-modal-confirm').disabled = true;
	document.getElementById('asset-check-out-action-modal-cancel').disabled = true;

	// Hide confirmation alert
	inputModule.hideModalWithId('asset-check-out-action-modal');

	// Handle asset check out
	const assetID = assetGlobalAssetData.id;
	if (assetID != null) {
		updateCheckInOutStatusForAsset('check_out', assetID, null, quantityNumber, personnelId, null).then((group) => {
			if (group != null) {

				// Update display
				document.getElementById('asset-check-out-action-modal-confirm').disabled = false;
				document.getElementById('asset-check-out-action-modal-cancel').disabled = false;

				// Remove group from asset data
				assetGroups.push(group);
				assetGlobalAssetData.groups = [...assetGroups];

				// Update display
				populateCheckInOutDisplay();

			} else {

				// Update display
				document.getElementById('asset-check-out-action-modal-confirm').disabled = false;
				document.getElementById('asset-check-out-action-modal-cancel').disabled = false;

				// Set content
				document.getElementById('asset-check-in-out-error-modal-title').innerHTML = `Check Out ${typeString}`;
				document.getElementById('asset-check-in-out-error-modal-description').innerHTML = `We're Having Trouble Checking Out Your ${typeString}. Please Try Again.`;

				// Display error modal
				inputModule.showModalWithId('asset-check-in-out-error-modal');
			}
		});
	}
};


/**
 * Personnel Handlers
 */

const updatePersonnel = (searchText) => {
	Parse.Cloud.run('fetchPersonnelForAssignment', { searchText, page: 0 }).then((personnel) => {
		availablePersonnel = personnel;
	}).catch((error) => {
		if (error && error.code === 209) {
			externalUserModule.handleUserLogOut(true);
		}
	});
};


/**
 * Stock Level Widget Handlers
 */

const populateStockLevelWidgetDisplay = (inputId, value) => {
	if (assetGlobalShowStockLevelWidget === true) {

		// Update display
		$('#asset-stock-level-widget-holder').show();

		// Update elements
		$('#stock-level-widget-decrement-button').attr('data-input-id', inputId);
		$('#stock-level-widget-increment-button').attr('data-input-id', inputId);
		$('#asset-stock-level-widget-number').attr('data-set-input-id', inputId);
		$('#asset-stock-level-widget-number').html(value);

	} else {

		// Update display
		$('#asset-stock-level-widget-holder').hide();
	}
};


/**
 * Handlers
 */

const displayGuessContent = (items) => {

	// Get holder element
	const holder = document.getElementById('asset-guess-holder');

	// Remove existing elements
	holder.innerHTML = '';

	// Get schema types
	let typeSchemas = [];
	if (assetGlobalConfiguration.search != null && assetGlobalConfiguration.search.type_schemas != null) {
		typeSchemas = assetGlobalConfiguration.search.type_schemas;
	}

	// Parse new items
	for (let i = 0; i < items.length; i += 1) {

		// Get item parameters
		const item = items[i];
		const { source } = item;
		const { type } = item;
		const { data } = item;
		const { id } = item;
		const scannedUser = item.user;
		const elementId = `${id}-guess-element`;

		// Get type schema for type
		if (typeSchemas[type] != null) {
			const schema = typeSchemas[type];

			// Get schema parameters
			const displayType = schema.display;
			const { primary } = schema;
			const { secondary } = schema;
			const { detail } = schema;
			const detailImage = detail.image;
			const detailFields = detail.fields;
			const initialStateExpanded = schema.initial_state_expanded;

			// Create primary display
			const primaryLabel = primary.label;
			let primaryContent = '';
			if (primary.type === 'identifier') {
				primaryContent = item.identifier;
			} else {
				let refData = data;
				for (let m = 0; m < primary.ref.length; m += 1) {
					const ref = primary.ref[m];
					const check = refData[ref];
					if (check != null) {
						if (Array.isArray(check)) {
							primaryContent = check;
						} else if (typeof check === 'string') {
							primaryContent = check;
						} else if (typeof check === 'object') {
							refData = check;
						}
					}
				}
			}
			if (primaryContent != null && primaryContent !== '') {
				if (primary.type === 'array' && primaryContent != null) primaryContent = primaryContent.join(', ');
			} else primaryContent = '';

			// Create secondary display
			const secondaryLabel = secondary.label;
			let secondaryContent = '';
			if (secondary.type === 'identifier') {
				secondaryContent = item.identifier;
			} else {
				let refData = data;
				for (let m = 0; m < secondary.ref.length; m += 1) {
					const ref = secondary.ref[m];
					const check = refData[ref];
					if (check != null) {
						if (Array.isArray(check)) {
							secondaryContent = check;
						} else if (typeof check === 'string') {
							secondaryContent = check;
						} else if (typeof check === 'object') {
							refData = check;
						}
					}
				}
			}
			if (secondaryContent != null && secondaryContent !== '') {
				if (secondary.type === 'array') secondaryContent = secondaryContent.join(', ');
			} else secondaryContent = '';

			// Create scanned label
			let scannedLabel = '';
			if (scannedUser != null) {
				scannedLabel = `<div class='asset-user-holder'><p>${scannedUser}</p></div>`;
			}

			// Create action button
			let actionTitle = 'Create this item';
			if (source === 'asset') actionTitle = 'Edit this item';

			// Setup asset inner height
			let assetInnerHeight = '';
			let assetInnerPrevHeight = "data-previous-height='100'";
			if (initialStateExpanded === false) {
				assetInnerHeight = "style='height:100px;'";
				assetInnerPrevHeight = "data-previous-height='#prevHeight'";
			}

			// Create new guess element
			let element = `<div class='asset-holder'>
            <div class='type-holder'><p>${displayType}</p></div>${scannedLabel}<div data-asset-id='${id}' class='action-holder' name='guess-action-holder'>
            <p>${actionTitle}<i class='fa fa-arrow-right' style='margin-left:5px; font-size:12px;' aria-hidden='true'></i></p></div>
            <div class='asset-card' data-element-id='${elementId}' name='guess-asset-card'
            data-expanded='${initialStateExpanded}' style='width:100%;'><div class='asset-card-inner' id='${elementId}' ${assetInnerHeight}${assetInnerPrevHeight}>
            <div style='float:left; width:#leftFloatWidth;'><p>${primaryLabel}<p><h3>${primaryContent}</h3><p>${secondaryLabel}</p><h3>${secondaryContent}</h3>`;

			// Parse detail content
			if (detailFields.length > 0) {

				// Create detail fields
				let validCount = 0;
				for (let j = 0; j < detailFields.length; j += 1) {

					// Get detail content
					const fieldLabel = detailFields[j].label;
					let fieldContent = '';
					if (detailFields[j].type === 'identifier') {
						fieldContent = item.identifier;
					} else {
						let refData = data;
						for (let m = 0; m < detailFields[j].ref.length; m += 1) {
							const ref = detailFields[j].ref[m];
							const check = refData[ref];
							if (check != null) {
								if (Array.isArray(check)) {
									fieldContent = check;
								} else if (typeof check === 'string') {
									fieldContent = check;
								} else if (typeof check === 'object') {
									refData = check;
								}
							}
						}
					}
					if (fieldContent != null && fieldContent !== '') {
						if (detailFields[j].type === 'array' && fieldContent != null) fieldContent = fieldContent.join(', ');
					} else fieldContent = '';

					// If content exists, set content
					if (fieldContent !== '') {
						element += `<p>${fieldLabel}<p><h3>${fieldContent}</h3>`;
						validCount += 1;
					}
				}
				const expandedHeight = 100 + (validCount * 50);
				element = element.replace('#prevHeight', expandedHeight.toString());
			}

			// Close left element
			element += "</div><div style='float:right; width:#rightFloatWidth; text-align:right;'>";

			// Create image
			if (detailImage != null) {

				// Get image url
				let imageURL = '';
				let refData = JSON.parse(JSON.stringify(data));
				for (let j = 0; j < detailImage.ref.length; j += 1) {
					const ref = detailImage.ref[j];
					const check = refData[ref];
					if (check != null) {
						if (typeof check === 'string') {
							imageURL = check;
						} else if (typeof check === 'object') {
							refData = check;
						}
					}
				}

				// If image url exists, display image
				if (imageURL !== '') {
					imageURL = imageURL.replace('http://', 'https://');
					element += `<img style='margin-top:5px; height:84px; width:auto; border-radius:4px;' src='${imageURL}' alt='Asset Preview' title='Asset Preview'>`;
					element = element.replace('#leftFloatWidth', '80%').replace('#rightFloatWidth', '20%');
				} else {
					element = element.replace('#leftFloatWidth', '100%').replace('#rightFloatWidth', '0%');
				}
			}

			// Close element
			element += '</div></div></div>';

			// Append new element
			holder.innerHTML += element;
		}
	}
};

const findContentWithHint = () => {

	// Clear search timeout
	if (assetGlobalTimeout != null) clearTimeout(assetGlobalTimeout);

	// Set new timeout
	assetGlobalTimeout = setTimeout(() => {

		// Format hint content
		let hintContent = document.getElementById('asset-guess-field').value;
		hintContent = hintContent.toLowerCase().replace(/\W/g, '');

		// Fetch data with hint
		Parse.Cloud.run('fetchAssetDataWithHint', { hintContent, hintType: 'searchTag' }).then((items) => {
			assetGlobalItems = items;

			// Display guess content
			displayGuessContent(items);

		}).catch((error) => {
			if (error && error.code === 209) {
				externalUserModule.handleUserLogOut(true);
			}
		});
	}, 400);
};

const editActionButtonText = () => {
	if (assetGlobalAssetId === 'new') {

		// Get hint content
		const hintContent = document.getElementById('asset-guess-field').value.replace(/-/g, '').replace(/\s/g, '');

		// Edit action button text
		if (hintContent === '') {
			assetGlobalActionButtonTitle = 'Create a New Asset';
			document.getElementById('asset-action-button-text').innerHTML = assetGlobalActionButtonTitle;
		} else {
			assetGlobalActionButtonTitle = `Create a New Asset with ID &#34;${hintContent}&#34;`;
			document.getElementById('asset-action-button-text').innerHTML = assetGlobalActionButtonTitle;
		}
	}
};

const populateAssetSchema = () => {

	// Get current user
	const currentUser = Parse.User.current();

	// Get user role
	const userRole = getCookie('userRole');

	// Get elements
	const formHolder = document.getElementById('asset-form-holder');

	// Ensure element is loaded
	$(formHolder).ready(() => {

		// Get features config
		const featuresDict = assetGlobalConfiguration.features;

		// Get type
		let type = assetGlobalAssetType;
		if (type == null) {
			type = assetGlobalAssetData.type;
			if (type == null) {

				// Attempt to get last scan type for user
				if (currentUser.get('data').last_scanned_type != null
					&& currentUser.get('data').last_scanned_type !== '') {
					type = currentUser.get('data').last_scanned_type;
				} else { // If null, get first available type in schema
					// eslint-disable-next-line no-restricted-syntax
					for (const property in assetGlobalConfiguration.scan.type_schemas) {
						if (Object.prototype.hasOwnProperty.call(assetGlobalConfiguration.scan.type_schemas, property)) {
							type = property;
							break;
						}
					}
				}
			}
		}

		// Get schema
		const inputArray = assetGlobalConfiguration.scan.type_schemas[type].input;

		// Set asset type
		assetGlobalAssetType = type;
		if (assetGlobalAssetData.type == null) {
			assetGlobalAssetData.type = type;
		}

		// Check if should show stock level widget
		assetGlobalShowStockLevelWidget = false;
		const showStockLevel = assetGlobalConfiguration.scan.type_schemas[type].show_stock_level_widget;
		if (showStockLevel != null) {
			assetGlobalShowStockLevelWidget = showStockLevel;
		}

		// Check if should track status
		assetGlobalCheckInOutEnabled = false;
		const trackStatus = assetGlobalConfiguration.scan.type_schemas[type].track_check_in_out_status;
		if (trackStatus != null) {
			assetGlobalCheckInOutEnabled = trackStatus;
		}

		// Check if should track geolocation
		assetGlobalGeolocationEnabled = false;
		const geolocation = assetGlobalConfiguration.scan.type_schemas[type].track_geolocation;
		if (geolocation != null) {
			assetGlobalGeolocationEnabled = geolocation;
		}

		// Get custom owner field
		customOwnerField = 'Scanned by';
		const ownerField = assetGlobalConfiguration.scan.type_schemas[type].custom_owner_field || 'Scanned by';
		if (ownerField != null) {
			customOwnerField = ownerField;
		}

		// Check if should show purchase option
		assetGlobalShowPurchaseOption = false;
		if (assetGlobalIsNew !== true) {
			const showPurchase = featuresDict.show_purchase_option;
			if (showPurchase != null) {
				assetGlobalShowPurchaseOption = showPurchase;
			}
		}

		// Parse groups
		if (assetGlobalCheckInOutEnabled === true) {
			const { groups } = assetGlobalAssetData;
			if (groups != null) {
				assetGroups = groups;
			}
		}

		// Get asset data
		let { data } = assetGlobalAssetData;
		if (data == null) data = {};

		// Check for user defaults
		for (let i = 0; i < inputArray.length; i += 1) {
			const input = inputArray[i];
			if (input.ref != null && input.ref.length > 0) {
				if (input.user_default != null && data[input.ref[0]] == null) {
					const userDefault = input.user_default;
					if (userDefault === 'firstName' || userDefault === 'lastName' || userDefault === 'email') {
						if (currentUser.get(userDefault) != null) {
							data[input.ref[0]] = currentUser.get(userDefault);
						}
					} else if (currentUser.get('data')[userDefault] != null) {
						data[input.ref[0]] = currentUser.get('data')[userDefault];
					}
				}
			}
		}

		// Set asset data
		assetGlobalAssetData.data = data;

		// Get identifier value
		let idValue = '';
		if (assetGlobalAssetData.identifier != null) {
			idValue = ` value= '${assetGlobalAssetData.identifier.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'`;
		}

		// Create identifier field
		const assetPrompt = assetGlobalConfiguration.scan.general.prompt;
		let html = `<fieldset style='margin:0px auto 0px;'>
            <input id='identifier' name='identifier' type='text' data-asset-prompt='${assetPrompt}' ${idValue}
             style='margin:0px;' required><label for='identifier' id='identifierLabel'>${assetPrompt}</label>
            </fieldset>`;

		// Create type select if more than one available types
		if (assetGlobalAvailableTypes.length > 1) {
			html += (`<fieldset style='margin:15px auto 0px;'>
                <select id='asset-type-asset-input-element'>`);

			// Create options
			for (let i = 0; i < assetGlobalAvailableTypes.length; i += 1) {
				let selected = '';
				if (assetGlobalAvailableTypes[i].value === type) {
					selected = " selected='selected' ";
				}
				html += (`<option ${selected}value='${
					assetGlobalAvailableTypes[i].value.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'>${
					assetGlobalAvailableTypes[i].display}</option>`);
			}
			html += (`</select>
                <label for='assetType' id='assetTypeLabel'>Type</label>
                </fieldset>`);
		}

		// Create owner field (disabled)
		if ((userRole === 'leader' || userRole === 'super' || userRole === 'admin') && assetGlobalAssetData.user != null) {
			html += (`<fieldset style='margin:15px auto 0px;'>
                <input id='owner' name='owner' type='text' style='margin:0px;' disabled value='${assetGlobalAssetData.user.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'
                required><label for='owner' id='ownerLabel'>${customOwnerField}</label>
                </fieldset>`);
		}

		// Create schema for type
		let stockLevelId = null;
		let stockLevelValue = '0';
		for (let i = 0; i < inputArray.length; i += 1) {

			// Get configuration
			const inputConfig = inputArray[i];
			let { prompt } = inputConfig;
			const inputType = inputConfig.type;
			const refArray = inputConfig.ref;
			const inputId = `${inputConfig.id}-asset-input`;
			const { editable } = inputConfig;
			const { choices } = inputConfig;

			// Get current data
			let refData = assetGlobalAssetData.data;
			let displayData = '';
			for (let j = 0; j < refArray.length; j += 1) {
				const ref = refArray[j];
				const check = refData[ref];
				if (check != null) {
					if (Array.isArray(check)) {
						for (let k = 0; k < check.length; k += 1) {
							if (k !== 0) {
								displayData += ', ';
							}
							displayData += check[k];
						}
					} else if (typeof check === 'string') {
						displayData = check;
					} else if (typeof check === 'object') {
						refData = check;
					}
				}
			}

			// Create input for type
			if (inputType === 'text' || inputType === 'array' || inputType === 'number'
				|| inputType === 'quantity' || inputType === 'price' || inputType === 'stockLevelThreshold') { // Input

				// Get input type
				let inputDisplayType = 'text';
				if (inputType === 'number' || inputType === 'quantity'
					|| inputType === 'price' || inputType === 'stockLevelThreshold') {
					inputDisplayType = 'number';
				}

				// Set stock level id
				if (inputType === 'quantity') {
					stockLevelId = inputId;
					stockLevelValue = displayData.replace(/"/g, '&#34;').replace(/'/g, '&#39;');
				}

				// Get enabled/disabled
				let enabledState = '';
				if (editable === false) enabledState = ' disabled ';

				// Update label
				if (inputType === 'price') {

					// Get parameters
					const currency = inputConfig.currency != null ? inputConfig.currency : 'USD';

					// Set label content
					prompt += ` (${currency})`;
				}

				// If numeric input, display increment / decrement buttons
				let buttonContent = '';
				if (inputType === 'number' || inputType === 'quantity' || inputType === 'price' || inputType === 'stockLevelThreshold') {
					buttonContent = `<div class='incr-decr-holder'>
                    <button class='increment-button animate' name='asset-increment-button' data-input-type='${inputType}' data-input-id='${inputId}'>
                    <div class="widget-plus-icon">
                    </button>
                    <button class='decrement-button animate' name='asset-decrement-button' data-input-type='${inputType}' data-input-id='${inputId}'>
                    <div class="widget-minus-icon">
                    </button>
                    </div>`;
				}

				// Set html
				html += (`<fieldset style='margin:15px auto 0px;'>
					<input id='${inputId}' name='${inputId}' data-prompt='${prompt}' type='${inputDisplayType}'
					 step='${inputType === 'price' ? 'any' : '1'}'
                     ${enabledState} value='${displayData.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'
                     style='margin:0px;' required><label for='${inputId}' id='${inputId}Label'>${prompt}</label>
                     ${buttonContent}</fieldset>`);

			} else if (inputType === 'choice' && choices != null && choices.length > 0) {

				// Set html
				html += (`<fieldset style='margin:15px auto 0px;'>
                    <select id='${inputId}' name='${inputId}' required>
                    <option value=''>Select an Option</option>`);

				// Create options
				for (let j = 0; j < choices.length; j += 1) {
					let selected = '';
					if (choices[j] != null && choices[j].id === displayData) {
						selected = " selected='selected' ";
					}
					html += (`<option ${selected}value='${choices[j].id}'>${
						choices[j].value}</option>`);
				}

				// Set html
				html += (`</select>
                    <label for='${inputId}' id='${inputId}Label' class='activated-label'>${prompt}</label>
                    </fieldset>`);
			} else if (displayData !== '') { // Image view
				displayData = displayData.replace('http://', 'https://');
				html += (`<img class='asset-image-holder' id='${inputId}' src='${displayData}' alt='Asset Preview' title='Asset Preview'>`);
			}
		}

		// Add organization dropdown if super or admin role
		if (userRole === 'super' || userRole === 'admin') {

			// Add org selection field
			html += (`<fieldset style='margin:15px auto 0px;'>
                <select id='asset-org-asset-input'>`);

			// Create options
			for (let i = 0; i < window.assetGlobalOrganizationArray.length; i += 1) {
				let selected = '';
				if (assetGlobalAssetData.organizationId != null) {
					if (window.assetGlobalOrganizationArray[i].id === assetGlobalAssetData.organizationId) {
						selected = " selected='selected' ";
					}
				} else if (window.assetGlobalOrganizationArray[i].id === assetGlobalOrganization.id) {
					selected = " selected='selected' ";
				}
				html += (`<option ${selected}value='${window.assetGlobalOrganizationArray[i].id}'>
                    ${window.assetGlobalOrganizationArray[i].name}</option>`);
			}
			html += (`</select>
                <label for='assetOrg' id='assetOrgLabel'>Owner Organization</label>
                </fieldset>`);
		}

		// Add html content
		formHolder.innerHTML = html;

		// Update purchase option display
		if (assetGlobalShowPurchaseOption === true) {
			$('#asset-purchase-option-holder').show();
		} else {
			$('#asset-purchase-option-holder').hide();
		}

		// Update stock level widget display
		populateStockLevelWidgetDisplay(stockLevelId, stockLevelValue);

		// Update check in out display
		populateCheckInOutDisplay();

		// Fetch personnel if necesssary
		if (assetGlobalCheckInOutEnabled === true && availablePersonnel.length === 0) {
			updatePersonnel(null);
		}
	});
};

const updateDisplayToSchema = (reverse) => {

	// Get elements
	const guessField = document.getElementById('asset-guess-field');
	const guessHolder = document.getElementById('asset-guess-holder');
	const formHolder = document.getElementById('asset-form-holder');
	const backButton = document.getElementById('asset-back-to-search');
	const actionButton = document.getElementById('asset-action-button-text');

	// Update display
	if (reverse === true) {

		// Update visibility
		guessField.style.display = 'block';
		guessHolder.style.display = 'block';
		formHolder.style.display = 'none';
		backButton.style.display = 'none';
		$('#asset-options-menu-holder').hide();

		// Update action button text
		actionButton.innerHTML = assetGlobalActionButtonTitle;

	} else {

		// Update visibility
		guessField.style.display = 'none';
		guessHolder.style.display = 'none';
		formHolder.style.display = 'block';
		backButton.style.display = 'block';

		// Set action button text
		if (assetGlobalAssetData.source === 'asset') {
			$('#asset-options-menu-holder').show();
			assetGlobalIsNew = false;
			actionButton.innerHTML = 'Update Asset';
		} else {
			$('#asset-options-menu-holder').hide();
			assetGlobalIsNew = true;
			actionButton.innerHTML = 'Save Asset';
		}

		// Populate schema fields
		populateAssetSchema();
	}
};

const handleAssetActionButton = (itemId) => {

	// Get selected item
	for (let i = 0; i < assetGlobalItems.length; i += 1) {
		if (assetGlobalItems[i].id === itemId) {
			assetGlobalAssetData = assetGlobalItems[i];
			break;
		}
	}

	// Update display
	if (assetGlobalAssetData != null) {

		// Set asset id
		assetGlobalAssetId = assetGlobalAssetData.id;

		// Update display to schema
		updateDisplayToSchema(false);
	}
};

const expandGuessElement = (elementId) => {

	// Get element
	const element = document.getElementById(elementId);

	// Get new height
	const newHeight = `${element.getAttribute('data-previous-height')}px`;

	// Set previous height
	element.setAttribute('data-previous-height', $(element).outerHeight());

	// Animate
	$(element).animate({ height: newHeight }, 200);
};

const handleDuplicateAsset = () => {

	// Display confirmation
	inputModule.showModalWithId('asset-duplicate-confirmation');
};

const performDuplicateAsset = () => {

	// Hide options button
	$('#asset-options-menu-holder').hide();

	// Set content
	document.getElementById('asset-action-button-text').innerHTML = 'Save Asset';
	document.getElementById('asset-title-label').innerHTML = 'Save Your Asset';

	// Reset globals
	assetGlobalAssetType = null;

	// Create new asset data
	const newAssetData = JSON.parse(JSON.stringify(assetGlobalAssetData));
	newAssetData.id = null;
	newAssetData.objectId = null;
	assetGlobalAssetData = newAssetData;

	// Populate asset schema
	populateAssetSchema();

	// Hide confirmation modal
	inputModule.hideModalWithId('asset-duplicate-confirmation');
};

const backToSearch = () => {

	// Reset global data
	assetGlobalAssetData = null;
	assetGlobalAssetId = 'new';
	assetGlobalAssetType = null;

	// Update display to search
	updateDisplayToSchema(true);
};

const assetTypeDidChange = (selectObj) => {
	assetGlobalAssetType = selectObj.value;
	populateAssetSchema();
};

const handleAssetPageActionButton = () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Create new asset
	if (assetGlobalAssetData == null) {

		// Set asset data
		assetGlobalAssetId = document.getElementById('asset-guess-field').value.replace(/-/g, '').replace(/\s/g, '');

		// Attempt to fetch asset with identifier
		if (assetGlobalAssetId !== '') {

			// Initialize asset data
			assetGlobalAssetData = {
				identifier: assetGlobalAssetId
			};

			// Update display to schema
			updateDisplayToSchema(false);
		} else {

			// Initialize asset data
			assetGlobalAssetData = {};

			// Update display to schema
			updateDisplayToSchema(false);
		}
	} else { // Save asset

		// Get general parameters
		const identifier = document.getElementById('identifier').value.replace(/-/g, '').replace(/\s/g, '');
		const type = assetGlobalAssetType;

		// Validate general parameters
		if (identifier === '') {

			// Display error
			const identifierLabel = document.getElementById('identifierLabel');
			identifierLabel.innerHTML = 'Asset ID (Required)';
			identifierLabel.className = 'errorLabel';
			identifierLabel.focus();

		} else if (type == null || type === '') {

			// Display error
			inputModule.showModalWithId('asset-type-error-modal');
		} else {

			// Update display
			const buttonTitle = document.getElementById('asset-action-button-text').innerHTML;
			document.getElementById('asset-action-button').disabled = true;
			document.getElementById('asset-action-button-text').innerHTML = '';
			document.getElementById('asset-action-button-arrow').style.display = 'none';
			document.getElementById('asset-button-activity-indicator').style.display = 'block';

			// Set general parameters
			assetGlobalAssetData.identifier = identifier;
			assetGlobalAssetData.type = type;

			// Get asset organization
			let organizationId;
			if (userRole === 'standard' || userRole === 'leader') {
				organizationId = assetGlobalOrganization.id;

			} else if (userRole === 'super' || userRole === 'admin') {
				organizationId = document.getElementById('asset-org-asset-input').value;
			}

			// Validate organization
			if (organizationId != null) {

				// Set organization
				assetGlobalAssetData.organizationId = organizationId;

				// Get specific parameters
				const inputArray = assetGlobalConfiguration.scan.type_schemas[type].input;
				for (let i = 0; i < inputArray.length; i += 1) {

					// Get configuration
					const inputConfig = inputArray[i];
					const inputType = inputConfig.type;
					const refArray = inputConfig.ref;
					const inputId = `${inputConfig.id}-asset-input`;
					const { editable } = inputConfig;

					// Check if should save parameter
					if (editable === true) {

						// Get current parameter
						let parameter;
						if (inputType !== 'image') {
							if (document.getElementById(inputId)) {
								parameter = document.getElementById(inputId).value;
							}
						} else if (document.getElementById(inputId)) {
							parameter = document.getElementById(inputId).src;
						}

						// Validate parameter
						if (parameter != null) {
							if (inputType === 'array') {
								parameter = parameter.split(',');
								for (let j = 0; j < parameter.length; j += 1) {
									parameter[j] = parameter[j].trim();
								}
							}

							// Set parameter
							let schema = assetGlobalAssetData.data;
							for (let j = 0; j < refArray.length - 1; j += 1) {
								const ref = refArray[j];
								if (!schema[ref]) schema[ref] = {};
								schema = schema[ref];
							}
							schema[refArray[refArray.length - 1]] = parameter;
						}
					}
				}

				// Set asset data
				const assetDataToSave = JSON.parse(JSON.stringify(assetGlobalAssetData));
				assetDataToSave.assetData = assetDataToSave.data;
				assetDataToSave.data = null;

				// Set asset object id
				assetDataToSave.objectId = assetDataToSave.id;
				assetDataToSave.id = null;

				// Save asset
				Parse.Cloud.run('saveAssetWithParameters', { assetData: assetDataToSave, platform: 'web' }).then(async (assetEntity) => {

					// Get asset data
					const assets = await userModule.getAssetsForUser(false);

					// Set last_scanned_type
					const currentUser = Parse.User.current();
					currentUser.get('data').last_scanned_type = type;

					// Add standard parameters
					const now = new Date().toISOString();
					assetGlobalAssetData.createdAt = now;
					assetGlobalAssetData.updatedAt = now;

					// Set new asset data to storage
					let foundAsset = false;
					for (let i = 0; i < assets.length; i += 1) {
						if (assets[i].id === assetGlobalAssetData.id) {
							assetGlobalAssetData.user = assets[i].user;
							assets[i] = assetGlobalAssetData;
							foundAsset = true;
							break;
						}
					}
					if (foundAsset === false) {

						// Create current user name
						const userName = `${currentUser.get('firstName')} ${currentUser.get('lastName')}`;

						// Create organization name
						const orgName = assetGlobalOrganization.get('name');
						if (orgName != null) assetGlobalAssetData.organization = orgName;

						// Update global count
						if (window.assetsGlobalTotalNumber != null) window.assetsGlobalTotalNumber += 1;

						// Set parameters and append
						assetGlobalAssetData.user = userName;
						assetGlobalAssetData.id = assetEntity.id;
						assetGlobalAssetData.searchTag = assetEntity.searchTag;
						assetGlobalAssetData.assetCount = window.assetsGlobalTotalNumber;
						assets.unshift(assetGlobalAssetData);

						// Set global count
						assets[assets.length - 1].assetCount = window.assetsGlobalTotalNumber;
					}

					// Set asset array to storage
					window.assetsGlobalAssetArray = [...assets];

					// Update state
					window.assetsShouldReloadAssets = true;

					// Update display
					document.getElementById('asset-button-activity-indicator').style.display = 'none';
					document.getElementById('asset-action-button-text').innerHTML = 'Successfully Saved Asset';

					// Set delay to Assets
					setTimeout(() => { appModule.handleRouting('/dashboard/assets'); }, 1000);

				}).catch((error) => {
					if (error && error.code === 209) {
						externalUserModule.handleUserLogOut(true);
					} else {

						// Update display
						document.getElementById('asset-action-button').disabled = false;
						document.getElementById('asset-action-button-text').innerHTML = buttonTitle;
						document.getElementById('asset-action-button-arrow').style.display = 'inline-block';
						document.getElementById('asset-button-activity-indicator').style.display = 'none';

						// Display error
						inputModule.showModalWithId('asset-save-error-modal');
					}
				});
			} else {

				// Update display
				document.getElementById('asset-action-button').disabled = false;
				document.getElementById('asset-action-button-text').innerHTML = buttonTitle;
				document.getElementById('asset-action-button-arrow').style.display = 'inline-block';
				document.getElementById('asset-button-activity-indicator').style.display = 'none';
			}
		}
	}
};

const removeAsset = () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Validate user role
	if (userRole === 'leader' || userRole === 'super' || userRole === 'admin') {

		// Show remove confirmation
		inputModule.showModalWithId('asset-single-archive-confirmation');
	}
};

const handleRemoveAsset = (confirm) => {
	if (confirm === true && assetGlobalAssetId != null) {

		// Hide remove confirmation
		inputModule.hideModalWithId('asset-single-archive-confirmation');

		// Remove assets from asset dictionary
		const assetArray = [];
		// eslint-disable-next-line no-restricted-syntax
		for (const property in window.assetsGlobalAssetDictionary) {
			if (Object.prototype.hasOwnProperty.call(window.assetsGlobalAssetDictionary, property)) {
				const displayArray = window.assetsGlobalAssetDictionary[property];
				for (let i = displayArray.length - 1; i >= 0; i -= 1) {
					if (assetGlobalAssetId === displayArray[i].id) {
						displayArray.splice(i, 1);
						break;
					}
				}
				Array.prototype.push.apply(assetArray, displayArray);
			}
		}

		// Remove assets from display dictionary
		// eslint-disable-next-line no-restricted-syntax
		for (const property in window.assetsGlobalDisplayDictionary) {
			if (Object.prototype.hasOwnProperty.call(window.assetsGlobalDisplayDictionary, property)) {
				const displayArray = window.assetsGlobalDisplayDictionary[property];
				for (let i = displayArray.length - 1; i >= 0; i -= 1) {
					if (assetGlobalAssetId === displayArray[i].id) {
						displayArray.splice(i, 1);
					}
				}
			}
		}

		// Subtract from total
		if (window.assetsGlobalTotalNumber) window.assetsGlobalTotalNumber -= 1;

		// Set asset array to session storage
		try {
			sessionStorage.setItem('user-assets', JSON.stringify(assetArray));
		} catch (e) { }

		// Archive assets
		Parse.Cloud.run('archiveAssetsWithParameters', { assetIDArray: [assetGlobalAssetId], platform: 'web' }).then(async () => {

			// Get asset data
			const assets = await userModule.getAssetsForUser(false);

			// Set new asset data to storage
			let assetIndex = -1;
			for (let i = 0; i < assets.length; i += 1) {
				if (assets[i].id === assetGlobalAssetData.id) {
					assetIndex = i;
					break;
				}
			}
			if (assetIndex > -1) assets.splice(assetIndex, 1);

			// Set asset array to storage
			window.assetsGlobalAssetArray = [...assets];

			// Update state
			window.assetsShouldReloadAssets = true;

			// Back to assets
			appModule.handleRouting('/dashboard/assets');

		}).catch((error) => {
			if (error && error.code === 209) {
				externalUserModule.handleUserLogOut(true);
			} else {

				// Show error
				inputModule.showModalWithId('asset-single-archive-error-modal');
			}
		});
	} else {

		// Hide remove confirmation
		inputModule.hideModalWithId('asset-single-archive-confirmation');
	}
};


/**
 * Activity / History Handlers
 */

const populateActivityFeed = async () => {

	// Get asset type
	const config = await userModule.handleConfigurationForUser(false);
	assetGlobalActivityAssetType = JSON.parse(config).scan.type_schemas[assetGlobalAssetData.type].display;

	// Set content
	if (assetGlobalActivityContext === Constants.VIEW_LOAD_CONTEXTS.CONTEXT_HISTORY) {
		document.getElementById('assetactivity-title-label').innerHTML = 'Asset History';
		document.getElementById('assetactivity-error-label').innerHTML = `This ${assetGlobalActivityAssetType} Hasn't Been Updated Yet. Begin The Edit Process To See Updates.`;
	} else {
		document.getElementById('assetactivity-title-label').innerHTML = 'Asset Activity';
		document.getElementById('assetactivity-error-label').innerHTML = `This ${assetGlobalActivityAssetType} Hasn't Been Checked Out Yet. Begin The Check-Out Process To See Updates.`;
	}

	// Parse activity array
	const activityArray = [];
	const { activity: activityObj } = assetGlobalAssetData;
	if (activityObj != null) {
		const { events } = activityObj;
		if (events != null) {
			for (let i = 0; i < events.length; i += 1) {
				const status = events[i];
				if (assetGlobalActivityContext === Constants.VIEW_LOAD_CONTEXTS.CONTEXT_HISTORY) {
					activityArray.push(status);
				} else {
					const { action } = status;
					if (action != null) {
						if (action === 'check_in' || action === 'check_out') {
							activityArray.push(status);
						}
					}
				}
			}
		}
	}

	// Get elements
	const feedHolder = document.getElementById('assetactivity-feed-holder');
	const errorHolder = document.getElementById('assetactivity-error-holder');

	// Update display
	if (activityArray.length === 0) {
		feedHolder.style.display = 'none';
		errorHolder.style.display = 'block';
	} else {
		feedHolder.style.display = 'block';
		errorHolder.style.display = 'none';
	}

	// Build content
	let html = '';
	for (let i = 0; i < activityArray.length; i += 1) {

		// Get activity
		const activity = activityArray[i];

		// Get parameters
		let actionString = '';
		let nameString = '';
		let dateString = '';
		const {
			action,
			data,
			user_name: userName,
			date
		} = activity;
		if (action != null) {
			switch (action) {
				case 'create':
					actionString = `${assetGlobalActivityAssetType} Created`;
					break;
				case 'scan':
					actionString = `${assetGlobalActivityAssetType} Scanned`;
					break;
				case 'edit':
					actionString = `${assetGlobalActivityAssetType} Edited`;
					break;
				case 'check_in':
					actionString = 'Checked In';
					break;
				case 'check_out':
					actionString = 'Checked Out';
					if (data != null) {
						const { name } = data;
						if (name != null) {
							actionString += ` - ${name}`;
						}
					}
					break;
				case 'stock_level_updated':
					actionString = 'Stock Level Updated';
					if (data != null) {
						const { pre_stock_level: pre, post_stock_level: post } = data;
						if (pre != null && post != null) {
							actionString += ` (${pre} -> ${post})`;
						}
					}
					break;
				default:
					break;
			}
		}
		if (userName != null) {
			nameString = userName;
			const { platform } = activity;
			if (platform != null) {
				switch (platform) {
					case 'ios':
						nameString += ' (iOS)';
						break;
					case 'android':
						nameString += ' (Android)';
						break;
					case 'web':
						nameString += ' (Web)';
						break;
					default:
						break;
				}
			}
		}
		if (date != null) {
			dateString = `${moment(date).format('MMM D, YYYY')} at ${moment(date).format('h:mm a')}`;
		}

		// Create content
		html += `<div class="asset-activity-holder">
        <h4>Action</h4>
        <h3>${actionString}</h3>
        <div class="asset-activity-divider"></div>
        <h4>User</h4>
        <h3>${nameString}</h3>
        <div class="asset-activity-divider"></div>
        <h4>Date</h4>
        <h3>${dateString}</h3>
        </div>`;
	}

	// Set content
	feedHolder.innerHTML = html;
};


/**
 * Purchase Handlers
 */

const handlePurchaseAsset = () => {

	// Create cart payload
	const assetID = assetGlobalAssetData.id;
	if (assetID != null) {
		const params = {
			payload: [{ id: assetID, quantity: 1, action: 'add' }],
			platform: 'web'
		};

		// Update cart with asset
		Parse.Cloud.run('updateCartForUser', params).then((success) => {
			if (success === true) {

				// Display cart
				appModule.handleRouting('/dashboard/cart');

			} else {

				// Display error modal
				inputModule.showModalWithId('purchase-asset-error-modal');
			}
		}).catch((error) => {
			if (error && error.code === 209) {
				externalUserModule.handleUserLogOut(true);
			} else {

				// Display error modal
				inputModule.showModalWithId('purchase-asset-error-modal');
			}
		});
	}
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on back button
	$('#asset-back-button').click(() => {
		appModule.handleRouting('/dashboard/assets');
	});

	// Handle click on action button
	$('#asset-action-button').click(() => {
		handleAssetPageActionButton();
	});

	// Handle click on delete button
	$('#asset-delete-asset-button').click(() => {
		removeAsset();
	});

	// Handle click on back to search button
	$('#asset-back-to-search').click(() => {
		backToSearch();
	});

	// Handle click on modal confirm button
	$('#asset-type-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('asset-type-error-modal');
	});

	// Handle click on modal confirm button
	$('#asset-save-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('asset-save-error-modal');
	});

	// Handle click on modal cancel button
	$('#asset-single-archive-confirmation-cancel').click(() => {
		handleRemoveAsset(false);
	});

	// Handle click on modal remove button
	$('#asset-single-archive-confirmation-remove').click(() => {
		handleRemoveAsset(true);
	});

	// Handle click on modal confirm button
	$('#asset-single-archive-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('asset-single-archive-error-modal');
	});

	// Handle click on modal remove button
	$('#asset-asset-duplicate-confirmation-duplicate').click(() => {
		performDuplicateAsset();
	});

	// Handle click on modal confirm button
	$('#asset-duplicate-confirmation-cancel').click(() => {
		inputModule.hideModalWithId('asset-duplicate-confirmation');
	});

	// Handle click on modal check in out button
	$('#asset-check-in-out-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('asset-check-in-out-error-modal');
	});

	// Handle click on modal check in button
	$('#asset-check-in-action-modal-cancel').click(() => {
		inputModule.hideModalWithId('asset-check-in-action-modal');
	});

	// Handle click on modal check in button
	$('#asset-check-in-action-modal-confirm').click(() => {
		performCheckIn();
	});

	// Handle click on modal check out button
	$('#asset-check-out-action-modal-cancel').click(() => {
		inputModule.hideModalWithId('asset-check-out-action-modal');
	});

	// Handle click on modal check out button
	$('#asset-check-out-action-modal-confirm').click(() => {
		performCheckOut();
	});

	// Handle click on purchase error modal confirm button
	$('#purchase-asset-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('purchase-asset-error-modal');
	});

	// Handle click on guess action holder
	$(document).off('click', "[name='guess-action-holder']");
	$(document).on('click', "[name='guess-action-holder']", function () {
		handleAssetActionButton($(this).data('asset-id'));
	});

	// Handle click on guess asset card
	$(document).off('click', "[name='guess-asset-card']");
	$(document).on('click', "[name='guess-asset-card']", function () {
		expandGuessElement($(this).data('element-id'));
	});

	// Handle click on increment button
	$(document).off('click', "button[name='asset-increment-button']");
	$(document).on('click', "button[name='asset-increment-button']", function () {
		inputModule.handleIncrementInput($(this).data('input-id'), $(this).data('input-type') === 'price');
	});

	// Handle click on decrement button
	$(document).off('click', "button[name='asset-decrement-button']");
	$(document).on('click', "button[name='asset-decrement-button']", function () {
		inputModule.handleDecrementInput($(this).data('input-id'), $(this).data('input-type') === 'price');
	});

	// Handle click on check in action
	$(document).off('click', "[name='asset-check-in-action']");
	$(document).on('click', "[name='asset-check-in-action']", function () {
		handleCheckIn($(this).data('group-id'));
	});

	// Handle click on options menu
	$('#asset-options-menu').click(() => {
		helperModule.displayActionSheet('asset-option-dropdown');
	});

	// Handle click on duplicate option
	$('#asset-duplicate-asset').click(() => {

		// Handle duplicate asset
		handleDuplicateAsset();
	});

	// Handle click on asset history option
	$('#asset-view-asset-history').click(() => {

		// Set state
		assetGlobalActivityContext = Constants.VIEW_LOAD_CONTEXTS.CONTEXT_HISTORY;

		// Move to activity
		appModule.handleRouting(`/dashboard/asset/activity/${assetGlobalAssetId}`);
	});

	// Handle click on check in out action button
	$('#asset-check-in-out-action-button').click(() => {

		// Handle check out
		handleCheckOut();
	});

	// Handle click on check in out asset activity
	$('#asset-check-in-out-asset-activity').click(() => {

		// Set state
		assetGlobalActivityContext = Constants.VIEW_LOAD_CONTEXTS.CONTEXT_ACTIVITY;

		// Move to activity
		appModule.handleRouting(`/dashboard/asset/activity/${assetGlobalAssetId}`);
	});

	// Handle click on purchase asset button
	$('#asset-purchase-option-holder').click(() => {

		// Handle purchase asset
		handlePurchaseAsset();
	});
};

const createActivityActionHandlers = () => {

	// Handle click on activity back button
	$(document).off('click', '#assetactivity-back-button');
	$(document).on('click', '#assetactivity-back-button', () => {
		appModule.handleRouting(`/dashboard/asset/${assetGlobalAssetId}`);
	});
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle blur action
	$(document).off('blur', '#identifier');
	$(document).on('blur', '#identifier', function () {
		inputModule.removeAlert('identifierLabel', $(this).data('asset-prompt'), true);
	});
	$(document).off('blur', '#owner');
	$(document).on('blur', '#owner', () => {
		inputModule.removeAlert('ownerLabel', 'Asset #', true);
	});
	$(document).off('blur', "[id$='-asset-input']");
	$(document).on('blur', "[id$='-asset-input']", function () {
		if (!$(this).is('select')) {
			inputModule.removeAlert(`${$(this).attr('id')}Label`, $(this).data('prompt'), true);
		} else {
			inputModule.removeBlockAlert();
		}
	});

	// Handle keyup action
	$('#asset-guess-field').keyup(() => {
		findContentWithHint();
		editActionButtonText();
	});

	// Handle keydown action
	$(document).off('keydown', '#identifier');
	$(document).on('keydown', '#identifier', function () {
		inputModule.removeAlert('identifierLabel', $(this).data('asset-prompt'), true);
	});
	$(document).off('keydown', '#owner');
	$(document).on('keydown', '#owner', () => {
		inputModule.removeAlert('ownerLabel', 'Asset #', true);
	});
	$(document).off('keydown', "[id$='-asset-input']");
	$(document).on('keydown', "[id$='-asset-input']", function () {
		inputModule.removeAlert(`${$(this).attr('id')}Label`, $(this).data('prompt'), true);
	});

	// Handle change content
	$(document).off('change', '#asset-type-asset-input-element');
	$(document).on('change', '#asset-type-asset-input-element', function () {
		assetTypeDidChange(this);
	});

	// Handle focus action
	$(document).off('focus', '#identifier');
	$(document).on('focus', '#identifier', () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', '#owner');
	$(document).on('focus', '#owner', () => {
		inputModule.removeBlockAlert();
	});
	$(document).off('focus', "[id$='-asset-input']");
	$(document).on('focus', "[id$='-asset-input']", () => {
		inputModule.removeBlockAlert();
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Fetch configuration for user
	const config = await userModule.handleConfigurationForUser(false);
	assetGlobalConfiguration = JSON.parse(config);

	// Reset variables
	assetGlobalAssetData = null;
	assetGlobalAssetType = null;
	assetGlobalAssetId = null;
	assetGlobalActionButtonTitle = 'Create a New Asset';

	// Update purchase button
	let flowAction = 'Purchase Asset';
	const featuresDict = assetGlobalConfiguration.features;
	const purchaseConfig = featuresDict.purchase_option_configuration;
	if (purchaseConfig != null) {
		const action = purchaseConfig.flow_action;
		if (action != null) {
			flowAction = action;
		}
	}
	$('#asset-purchase-option-button').html(flowAction);

	// Fetch organizations for user
	const { organization } = await externalUserModule.fetchConfigurationForUser();
	assetGlobalOrganization = organization;

	// Fetch organizations for user
	const organizations = await userModule.fetchPlatformOrganizationsForUser(true);
	window.assetGlobalOrganizationArray = [...organizations];

	// Get available types
	assetGlobalAvailableTypes = [];
	Object.keys(assetGlobalConfiguration.scan.type_schemas).forEach((property) => {
		if (Object.prototype.hasOwnProperty.call(assetGlobalConfiguration.scan.type_schemas, property)) {
			assetGlobalAvailableTypes.push({
				value: property,
				display: assetGlobalConfiguration.scan.type_schemas[property].display
			});
		}
	});

	// Get asset id
	assetGlobalAssetId = window.location.pathname.replace('/dashboard/asset/', '');
	if (assetGlobalAssetId !== 'new') { // Existing asset

		// Set state
		assetGlobalIsNew = false;

		// Get asset data
		const assets = await userModule.getAssetsForUser(false);
		for (let i = 0; i < assets.length; i += 1) {
			if (assets[i].id === assetGlobalAssetId) {
				assetGlobalAssetData = assets[i];
				break;
			}
		}
		if (assetGlobalAssetData != null && assetGlobalAssetData.activity != null) {

			// Hide loading indicator
			const loadingIndicator = document.getElementById('asset-activity-indicator');
			$(loadingIndicator).ready(() => {
				loadingIndicator.remove();
			});

			// Populate schema fields
			populateAssetSchema();

		} else { // Fetch asset with id
			Parse.Cloud.run('fetchAssetWithId', { fetchId: assetGlobalAssetId }).then((asset) => {
				if (asset != null) {

					// Set organization data
					assetGlobalAssetData = asset;

					// Hide loading indicator
					const loadingIndicator = document.getElementById('asset-activity-indicator');
					$(loadingIndicator).ready(() => {
						loadingIndicator.remove();
					});

					// Populate schema fields
					populateAssetSchema();

				} else {
					appModule.handleRouting('/dashboard/assets');
				}
			}).catch(() => {
				appModule.handleRouting('/dashboard/assets');
			});
		}
	}
};

exports.handlerDidAppear = async () => { };

exports.handlerAssetActivity = async () => {

	// Create action handlers
	createActivityActionHandlers();

	// Get activity id
	assetGlobalAssetId = window.location.pathname.replace('/dashboard/asset/activity/', '');
	if (assetGlobalAssetId != null && assetGlobalAssetId !== '') {

		// Check for asset data
		if (assetGlobalAssetData != null && assetGlobalAssetData.activity != null) {

			// Hide loading indicator
			const loadingIndicator = document.getElementById('assetactivity-activity-indicator');
			$(loadingIndicator).ready(() => {
				loadingIndicator.remove();
			});

			// Create activity feed
			populateActivityFeed();

		} else { // Fetch asset with id
			Parse.Cloud.run('fetchAssetWithId', { fetchId: assetGlobalAssetId }).then((asset) => {
				if (asset != null) {

					// Set organization data
					assetGlobalAssetData = asset;

					// Hide loading indicator
					const loadingIndicator = document.getElementById('assetactivity-activity-indicator');
					$(loadingIndicator).ready(() => {
						loadingIndicator.remove();
					});

					// Create activity feed
					populateActivityFeed();

				} else {
					appModule.handleRouting('/dashboard/assets');
				}
			}).catch(() => {
				appModule.handleRouting('/dashboard/assets');
			});
		}
	} else {
		appModule.handleRouting('/dashboard/assets');
	}
};
