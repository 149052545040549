// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const moment = require('moment-timezone');
const inputModule = require('../modules/input');
const externalUserModule = require('../modules/external-user');
const userModule = require('../modules/user');
const helperModule = require('../modules/helper');
const appModule = require('../modules/app');
const { getCookie, formatDateTime } = require('../modules/utilities');


/**
 * State
 */

let assetsGlobalAvailableTypes;
let assetsGlobalSelectedFilter;
let assetsGlobalSelectedSortDict;
let assetsGlobalTableState;
let assetsGlobalSearchTimeout;
let assetsGlobalConfiguration;
let isSettingUpStickHeaders = false;


/**
 * Drag State
 */

let dragX;
let dragY;
let dragTop;
let dragLeft;
let dragDown;
let dragElement;
let isDragging = false;


/**
 * Global State
 */

window.assetsGlobalTotalNumber = 0;
window.assetsShouldReloadAssets = false;


/**
 * Handlers
 */

const updateAssetDisplay = () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Get elements
	const holder = document.getElementById('assets-table-holder');
	const titleNumber = document.getElementById('assets-title-number');
	const removeButton = document.getElementById('assets-remove-button');

	// Create available types array
	let availableTypes = JSON.parse(JSON.stringify(assetsGlobalAvailableTypes));
	if (assetsGlobalSelectedFilter != null) availableTypes = [assetsGlobalSelectedFilter];

	// Ensure element is loaded
	if (titleNumber != null) {

		// Get total count
		let count = 0;
		const types = JSON.parse(JSON.stringify(assetsGlobalAvailableTypes));
		for (let i = 0; i < types.length; i += 1) {
			const property = types[i].value;
			if (window.assetsGlobalAssetDictionary[property] != null) {
				count += window.assetsGlobalAssetDictionary[property].length;
			}
		}

		// Set asset count
		titleNumber.innerHTML = `(${inputModule.formatCountNumber(count)}/${inputModule.formatCountNumber(window.assetsGlobalTotalNumber)})`;
	}

	// Hide remove button
	if (removeButton != null) {
		removeButton.style.display = 'none';
		removeButton.enabled = true;
	}

	// Ensure element is loaded
	if (holder != null) {

		// Initialize content
		let htmlContent = '';

		// Create asset type collections
		for (let i = 0; i < availableTypes.length; i += 1) {

			// Get asset collection
			const type = availableTypes[i];
			let displayArray = window.assetsGlobalDisplayDictionary[type.value];
			if (displayArray == null) displayArray = [];
			const sortSchema = assetsGlobalSelectedSortDict[type.value];

			// Get schema for type
			const schema = JSON.parse(JSON.stringify(assetsGlobalConfiguration.display.type_schemas[type.value]));
			const columnArray = schema.columns;
			const trackStockThreshold = schema.track_stock_threshold;
			const hideIfEmpty = schema.hide_if_empty;

			// Check if should track check in/out status
			const scanSchema = JSON.parse(JSON.stringify(assetsGlobalConfiguration.scan.type_schemas[type.value]));
			const trackCheckInOut = scanSchema.track_check_in_out_status || false;

			// Skip if empty
			if (hideIfEmpty === true && displayArray.length === 0) {
				// eslint-disable-next-line no-continue
				continue;
			}

			// Create collection content
			let html = `<h3>Asset Type<span class='bubble'>${type.display}</span><span>(${displayArray.length})</span>
				<a id='assets-table-load-more-${type.value}' class='load-more-button'>Load More<i class="fa fa-arrow-right"
				style="margin-left:5px; font-size:11px;" aria-hidden="true"></i></a></h3>
				<div class='card-content'><div class='table-container' id='assets-table-view-${type.value}'><div>
				<table><thead><tr>`;

			// Append selector schema item
			let offset = 0;
			if (trackCheckInOut) {
				columnArray.unshift({
					type: 'check_in_out'
				});
				offset += 1;
			}
			if (userRole === 'leader' || userRole === 'super' || userRole === 'admin') {
				columnArray.unshift({
					type: 'selector'
				});
				offset += 1;
			}

			// Create column headers
			for (let j = 0; j < columnArray.length; j += 1) {
				const column = columnArray[j];
				let arrow = '';
				if (sortSchema.column === (j - offset)) {
					if (sortSchema.reverse === false) {
						arrow = " <img src='/img/icons/menu-arrow.png' class='sort-arrow' alt='Sort Arrow' title='Sort Arrow'>";
					} else {
						arrow = " <img src='/img/icons/menu-arrow-open.png' class='sort-arrow' alt='Sort Arrow' title='Sort Arrow'>";
					}
				}
				if (column.type === 'selector') {
					html += "<th style='width:40px;'></th>";
				} else if (column.type === 'check_in_out') {
					html += '<th>Status</th>';
				} else {
					html += `<th name='asset-row-sort-element' data-type-value='${type.value}' data-offset='${(j - offset)}'>${column.label}${arrow}</th>`;
				}
			}
			html += '</tr></thead><tbody>';

			// Iterate through assets
			for (let j = 0; j < displayArray.length; j += 1) {

				// Get asset data
				const asset = displayArray[j];
				const { groups } = asset;
				const quantity = asset.quantity || 0;
				const quantityAvailable = asset.quantityAvailable || 0;

				// Calculate asset parameters
				const checkedOutQuantity = quantity - quantityAvailable;

				// Create new asset row
				html += `<tr name='asset-row-element' data-asset-id='${asset.id}' class='${j % 2 === 0 ? 'asset-row-even' : 'asset-row-odd'}'>`;
				for (let k = 0; k < columnArray.length; k += 1) {
					const column = columnArray[k];

					// Get content
					let fieldContent = '';
					if (column.type === 'identifier') {
						fieldContent = asset.identifier;
					} else if (column.type === 'user') {
						fieldContent = asset.user;
					} else if (column.type === 'organization') {
						fieldContent = asset.organization;
					} else if (column.type === 'scannedAt') {
						if (asset.scannedAt != null) {
							const date = new Date(asset.scannedAt);
							fieldContent = formatDateTime(date);
						} else {
							const date = new Date(asset.createdAt);
							fieldContent = formatDateTime(date);
						}
					} else if (column.type === 'createdAt') {
						const date = new Date(asset.createdAt);
						fieldContent = formatDateTime(date);
					} else {
						let refData = asset.data;
						if (column.ref != null) {
							for (let m = 0; m < column.ref.length; m += 1) {
								const ref = column.ref[m];
								const check = refData[ref];
								if (check != null) {
									if (Array.isArray(check)) {
										fieldContent = check;
									} else if (typeof check === 'string') {
										fieldContent = check;
									} else if (typeof check === 'object') {
										refData = check;
									}
								}
							}
						}
						if (fieldContent != null && fieldContent !== ''
							&& column.type === 'quantity' && trackStockThreshold === true) {

							// Get parameters
							let stockLevelThreshold = 3;
							if (asset.data.stockLevelThreshold != null) {
								const threshold = parseInt(asset.data.stockLevelThreshold, 10);
								if (!Number.isNaN(threshold)) stockLevelThreshold = threshold;
							}

							// Get difference
							const diff = parseInt(fieldContent, 10) - stockLevelThreshold;

							// Create difference badge
							if (diff === 0) {
								fieldContent += '<span class="emphasis red">At threshold</span>';
							} else if (diff < 0) {
								fieldContent += `<span class="emphasis red">${Math.abs(diff)} below threshold</span>`;
							} else {
								fieldContent += `<span class="emphasis green">${Math.abs(diff)} above threshold</span>`;
							}
						}
					}
					if (fieldContent != null && fieldContent !== '') {
						if (column.type === 'array' && fieldContent != null) fieldContent = fieldContent.join(', ');
						else if (column.type === 'choice' && fieldContent != null) {
							const { choices } = column;
							for (let m = 0; m < choices.length; m += 1) {
								if (choices[m].id === fieldContent) {
									fieldContent = choices[m].value;
									break;
								}
							}
						}
					} else fieldContent = '';

					// Set content
					if (column.type === 'selector') {
						html += (`<td style='width:40px; padding:0px 0px 0px 0px;'>
							</div>
							<div class='row-selector' name='asset-selector' id='asset-selector-${asset.id}'
							data-asset-id='${asset.id}'></div>
							</div>
							</td>`);
					} else if (column.type === 'check_in_out') {
						let statusColor = '--brand-green';
						let statusLabel = 'All copies checked in';
						if (quantity === 0) {
							statusColor = '--brand-green';
							statusLabel = 'All copies checked in';
						} else if (checkedOutQuantity === quantity) {
							statusColor = '--brand-orange';
							statusLabel = 'All copies checked out';
						} else if (checkedOutQuantity === 0) {
							statusColor = '--brand-green';
							statusLabel = 'All copies checked in';
						} else {
							statusColor = '--brand-accent-yellow';
							statusLabel = checkedOutQuantity === 1 ? '1 copy checked out' : `${checkedOutQuantity} copies checked out`;
						}
						html += (`<td><div class='asset-status-indicator' style="background-color:var(${statusColor});"></div><span>${statusLabel}</span></td>`);
					} else {
						html += (`<td>${fieldContent}</td>`);
					}
				}
				html += '</tr>';

				// Create groups markup
				if (groups.length > 0) {

					// Add markup
					html += `<tr class='${j % 2 === 0 ? 'asset-row-even' : 'asset-row-odd'}'><td></td><td colspan="100"><table class='asset-group-container'><tbody>`;

					// Iterate through groups
					for (let k = 0; k < groups.length; k += 1) {
						const group = groups[k];
						html += `<tr class='${j % 2 === 0 ? 'asset-row-even' : 'asset-row-odd'}'><td style="padding-left:0px; font-size:15px;"><span class="emphasis outline">${group.quantity} ${group.quantity === 1 ? 'copy' : 'copies'}</span> checked out to ${group.personnel.name}<span class="emphasis outline right">${`${moment(group.createdAt).format('MMM D, YYYY')} at ${moment(group.createdAt).format('h:mm a')}`}</span></td></tr>`;
					}

					// Add markup
					html += '</tbody></table></td></tr>';
				}
			}

			// Append html
			html += '</tbody></table>';
			htmlContent += (`${html}</div></div>`);

			// If no assets, append empty state
			if (displayArray.length === 0) {
				htmlContent += ("<h2 class='table-empty-state-text'>Scan or create an asset with this type to get started.</h2>");
			}
			htmlContent += '</div>';
		}

		// Set content
		holder.innerHTML = htmlContent;

		// Hide loading indicator
		const loadingIndicator = document.getElementById('assets-activity-indicator');
		if (loadingIndicator != null) {
			if (loadingIndicator != null) {
				loadingIndicator.remove();
			}
		}

		// Set current scroll position
		Object.keys(assetsGlobalTableState).forEach((property) => {
			if (Object.prototype.hasOwnProperty.call(assetsGlobalTableState, property)) {
				const { scroll } = assetsGlobalTableState[property];
				$(`#assets-table-view-${property}`).scrollLeft(scroll);
			}
		});

		// Set up table sticky table headers
		$('#assets-table-holder table').stickyTableHeaders({
			fixedOffset: $('#header-section .header'),
			cacheHeaderHeight: true
		});

		// Handle action on enable sticky header
		$('#assets-table-holder table').on('enabledStickiness.stickyTableHeaders', function () {

			// Set state
			isSettingUpStickHeaders = true;

			// Update header width
			const width = $(this).parent().width();
			$(this).find('.tableFloatingHeaderOriginal').each(function () {
				$(this).attr('style', `${$(this).attr('style')}; width: ${width}px !important`);
			});

		});

		// Handle actions on header width update
		$('#assets-table-holder table').on('updatedWidth.stickyTableHeaders', function () {

			// Set state
			isSettingUpStickHeaders = false;

			// Get current scroll value
			const scrollLeft = $(this).parent().parent().scrollLeft();

			// Update header width
			const width = $(this).parent().width();
			$(this).find('.tableFloatingHeaderOriginal').each(function () {
				$(this).attr('style', `${$(this).attr('style')}; width: ${width}px !important`);
				if (scrollLeft > 0) $(this).scrollLeft(scrollLeft);
			});
		});

		// Handle actions on manual header scroll
		$('#assets-table-holder table thead').scroll(function () {
			if (this.scrollLeft > 0 || isSettingUpStickHeaders !== true) {
				$(this).parent().parent().parent()
					.scrollLeft(this.scrollLeft);
			}
		});

		// Handle actions on manual table scroll
		$('#assets-table-holder table thead').parent().parent().parent()
			.scroll(function () {
				if (this.scrollLeft > 0 || isSettingUpStickHeaders !== true) {
					$(this).find('table thead').scrollLeft(this.scrollLeft);
				}
			});
	}
};

const updateAssetsForUser = async (shouldReload, typeArray, page) => {

	// Initialize dictionaries
	window.assetsGlobalAssetDictionary = {};
	window.assetsGlobalDisplayDictionary = {};

	// Get assets for user
	const assets = await userModule.getAssetsForUser(shouldReload, typeArray, page);

	// Set assets to dictionaries
	for (let i = 0; i < assets.length; i += 1) {
		const asset = assets[i];

		// Get asset count
		if (i === assets.length - 1) window.assetsGlobalTotalNumber = asset.assetCount;

		// Set assets
		let typeArr = window.assetsGlobalAssetDictionary[asset.type];
		if (typeArr == null) typeArr = [];
		typeArr.push(asset);
		window.assetsGlobalAssetDictionary[asset.type] = typeArr;
	}

	// Sort type array by largest number of assets
	assetsGlobalAvailableTypes.sort((a, b) => {
		if (window.assetsGlobalAssetDictionary[a.value] == null) window.assetsGlobalAssetDictionary[a.value] = [];
		if (window.assetsGlobalAssetDictionary[b.value] == null) window.assetsGlobalAssetDictionary[b.value] = [];
		return window.assetsGlobalAssetDictionary[b.value].length - window.assetsGlobalAssetDictionary[a.value].length;
	});

	// Copy data to display dictionary
	window.assetsGlobalDisplayDictionary = JSON.parse(JSON.stringify(window.assetsGlobalAssetDictionary));

	// Update asset display
	$(document).ready(() => {
		updateAssetDisplay();
	});
};

const loadMoreAssets = (type) => {

	// Create type array
	const typeArray = [type];

	// Update assets for user
	updateAssetsForUser(true, typeArray, window.assetsGlobalPageNumberDict[type] + 1);
};

const loadAllAssets = async () => {

	// Initialize dictionaries
	window.assetsGlobalAssetDictionary = {};
	window.assetsGlobalDisplayDictionary = {};

	// Fetch all assets
	const assets = await userModule.fetchAssetsForUser(true);

	// Set assets to dictionaries
	for (let i = 0; i < assets.length; i += 1) {
		const asset = assets[i];

		// Get asset count
		if (i === assets.length - 1) window.assetsGlobalTotalNumber = asset.assetCount;

		// Set assets
		let typeArray = window.assetsGlobalAssetDictionary[asset.type];
		if (typeArray == null) typeArray = [];
		typeArray.push(asset);
		window.assetsGlobalAssetDictionary[asset.type] = typeArray;
	}

	// Sort type array by largest number of assets
	assetsGlobalAvailableTypes.sort((a, b) => {
		if (window.assetsGlobalAssetDictionary[a.value] == null) window.assetsGlobalAssetDictionary[a.value] = [];
		if (window.assetsGlobalAssetDictionary[b.value] == null) window.assetsGlobalAssetDictionary[b.value] = [];
		return window.assetsGlobalAssetDictionary[b.value].length - window.assetsGlobalAssetDictionary[a.value].length;
	});

	// Copy data to display dictionary
	window.assetsGlobalDisplayDictionary = JSON.parse(JSON.stringify(window.assetsGlobalAssetDictionary));

	// Update asset display
	$(document).ready(() => {
		updateAssetDisplay();
	});
};

const setupAssetFilter = () => {

	// Get filter elements
	$(document).ready(() => {
		const elements = document.getElementsByName('assets-type-filter');
		for (let i = 0; i < elements.length; i += 1) {
			const element = elements[i];

			// Ensure element is loaded
			if (element.children.length <= 1) {

				// Create asset type filter options
				let html = '';
				for (let j = 0; j < assetsGlobalAvailableTypes.length; j += 1) {

					// Build new option
					html += (`<option value='${assetsGlobalAvailableTypes[j].value.replace(/"/g, '&#34;').replace(/'/g, '&#39;')}'>${assetsGlobalAvailableTypes[j].display}</option>`);
				}

				// Append option to filter
				element.innerHTML += html;
			}

		}
	});
};

const filterAssetType = (element) => {

	// Get selected option
	assetsGlobalSelectedFilter = {
		value: element.options[element.selectedIndex].value,
		display: element.options[element.selectedIndex].text
	};
	if (assetsGlobalSelectedFilter.value === '') assetsGlobalSelectedFilter = null;

	// Update asset display
	updateAssetDisplay();
};

const performGlobalAssetSearch = (searchValue) => {

	// Perform global search
	Parse.Cloud.run('performAssetSearchWithParameters', { searchText: searchValue }).then((assets) => {

		// Create asset dict
		const assetDict = {};
		for (let i = 0; i < assets.length; i += 1) {
			const asset = assets[i];

			// Set assets
			let typeArray = assetDict[asset.type];
			if (typeArray == null) typeArray = [];
			typeArray.push(asset);
			assetDict[asset.type] = typeArray;
		}

		// Iterate through asset types
		Object.keys(window.assetsGlobalDisplayDictionary).forEach((property) => {
			if (Object.prototype.hasOwnProperty.call(window.assetsGlobalDisplayDictionary, property)) {
				let displayArray = (window.assetsGlobalDisplayDictionary[property] != null) ? window.assetsGlobalDisplayDictionary[property] : [];
				const assetArray = (assetDict[property] != null) ? assetDict[property] : [];
				if (displayArray != null) {

					// Filter out existing assets
					for (let i = 0; i < assetArray.length; i += 1) {
						const asset = assetArray[i];
						let found = false;
						for (let j = 0; j < displayArray.length; j += 1) {
							const testAsset = displayArray[j];
							const { id } = asset;
							const testId = testAsset.id;
							if (id != null && testId != null) {
								if (id === testId) found = true;
							}
						}
						if (found === false) {
							displayArray.push(asset);
						}
					}
				} else {
					displayArray = [...assetArray];
				}
				window.assetsGlobalDisplayDictionary[property] = [...displayArray];
			}
		});

		// Update asset display
		updateAssetDisplay();
	});
};

const performAssetSearch = (element) => {

	// Cancel global search timer
	if (assetsGlobalSearchTimeout != null) clearTimeout(assetsGlobalSearchTimeout);

	// Get search value
	let searchValue = element.value;

	// If search value is empty, reset display array
	if (searchValue === '') {
		window.assetsGlobalDisplayDictionary = JSON.parse(JSON.stringify(window.assetsGlobalAssetDictionary));
	} else {

		// Format search value
		searchValue = searchValue.toLowerCase().replace(/\W/g, '');

		// Update values matching search string
		Object.keys(window.assetsGlobalAssetDictionary).forEach((property) => {
			if (Object.prototype.hasOwnProperty.call(window.assetsGlobalAssetDictionary, property)) {
				const displayArray = window.assetsGlobalAssetDictionary[property];
				const newDisplayArray = [];
				if (displayArray != null) {
					for (let i = 0; i < displayArray.length; i += 1) {
						const { searchTag } = displayArray[i];
						if (searchTag.indexOf(searchValue) > -1) newDisplayArray.push(displayArray[i]);
					}
				}
				window.assetsGlobalDisplayDictionary[property] = newDisplayArray;
			}
		});

		// Create global search timer
		assetsGlobalSearchTimeout = setTimeout(() => {
			performGlobalAssetSearch(searchValue);
		}, 800);
	}

	// Update asset display
	updateAssetDisplay();
};

const performAssetSort = (type, columnIndex) => {

	// Update selected sort
	let reverse = false;
	if (assetsGlobalSelectedSortDict[type].column === columnIndex) {
		if (assetsGlobalSelectedSortDict[type].reverse === false) {
			assetsGlobalSelectedSortDict[type].reverse = true;
			reverse = true;
		} else assetsGlobalSelectedSortDict[type].reverse = false;
	} else {
		assetsGlobalSelectedSortDict[type].column = columnIndex;
		assetsGlobalSelectedSortDict[type].reverse = false;
	}

	// Get sort column from schema
	const columnArray = assetsGlobalConfiguration.display.type_schemas[type].columns;
	const column = columnArray[columnIndex];

	// Sort display array
	window.assetsGlobalDisplayDictionary[type].sort((a, b) => {

		// Get content
		let aContent = '';
		let bContent = '';
		if (column.type === 'identifier') {
			aContent = a.identifier;
			bContent = b.identifier;
		} else if (column.type === 'user') {
			aContent = a.user;
			bContent = b.user;
		} else if (column.type === 'organization') {
			aContent = a.organization;
			bContent = b.organization;
		} else if (column.type === 'scannedAt') {
			if (a.scannedAt != null) aContent = a.scannedAt;
			else aContent = a.createdAt;
			if (b.scannedAt != null) bContent = b.scannedAt;
			else bContent = b.createdAt;
		} else if (column.type === 'createdAt') {
			aContent = a.createdAt;
			bContent = b.createdAt;
		} else {
			let aRefData = a.data;
			let bRefData = b.data;
			for (let m = 0; m < column.ref.length; m += 1) {
				const ref = column.ref[m];
				const aCheck = aRefData[ref];
				const bCheck = bRefData[ref];
				if (aCheck != null) {
					if (Array.isArray(aCheck)) {
						aContent = aCheck;
					} else if (typeof aCheck === 'string') {
						aContent = aCheck;
					} else if (typeof aCheck === 'object') {
						aRefData = aCheck;
					}
				}
				if (bCheck != null) {
					if (Array.isArray(bCheck)) {
						bContent = bCheck;
					} else if (typeof bCheck === 'string') {
						bContent = bCheck;
					} else if (typeof bCheck === 'object') {
						bRefData = bCheck;
					}
				}
			}
		}
		if (aContent != null && aContent !== '') {
			if (column.type === 'array' && aContent != null) aContent = aContent.join(', ');
			else if (column.type === 'choice' && aContent != null) {
				const { choices } = column;
				for (let m = 0; m < choices.length; m += 1) {
					if (choices[m].id === aContent) {
						aContent = choices[m].value;
						break;
					}
				}
			}
		} else aContent = '';
		if (bContent != null && bContent !== '') {
			if (column.type === 'array' && bContent != null) bContent = bContent.join(', ');
			else if (column.type === 'choice' && bContent != null) {
				const { choices } = column;
				for (let m = 0; m < choices.length; m += 1) {
					if (choices[m].id === bContent) {
						bContent = choices[m].value;
						break;
					}
				}
			}
		} else bContent = '';
		if (column.type === 'number' || column.type === 'quantity' || column.type === 'stockLevelThreshold') {
			aContent = parseInt(aContent, 10);
			bContent = parseInt(bContent, 10);
		} else if (column.type === 'price') {
			aContent = parseFloat(aContent);
			bContent = parseFloat(bContent);
		} else {
			if (aContent != null) aContent = aContent.toLowerCase();
			if (bContent != null) bContent = bContent.toLowerCase();
		}

		// Sort content
		if (!reverse) return (bContent < aContent) ? 1 : ((aContent < bContent) ? -1 : 0); // eslint-disable-line no-nested-ternary
		return (aContent < bContent) ? 1 : ((bContent < aContent) ? -1 : 0); // eslint-disable-line no-nested-ternary
	});

	// Update asset display
	updateAssetDisplay();
};

const selectAssetRow = (e, id) => {
	e.stopPropagation();

	// Select or deselect row
	if (document.getElementById(`asset-selector-${id}`).style.backgroundColor === '') {
		document.getElementById(`asset-selector-${id}`).style.backgroundColor = 'var(--brand-primary)';
	} else {
		document.getElementById(`asset-selector-${id}`).style.backgroundColor = '';
	}

	// Check selected counts
	let count = 0;
	const assets = document.getElementsByName('asset-selector');
	for (let i = 0; i < assets.length; i += 1) {
		if (assets[i].style.backgroundColor !== '') {
			count += 1;
		}
	}

	// Show or hide remove button
	if (count > 0) {
		document.getElementById('assets-remove-button').style.display = 'block';
		helperModule.handleRemoveButtonPosition();
	} else {
		document.getElementById('assets-remove-button').style.display = 'none';
	}
};

const removeAssets = () => {

	// Get user role
	const userRole = getCookie('userRole');

	// Validate user role
	if (userRole === 'leader' || userRole === 'super' || userRole === 'admin') {

		// Disable remove button
		document.getElementById('assets-remove-button').enabled = false;

		// Show remove confirmation
		inputModule.showModalWithId('asset-archive-confirmation');
	}
};

const handleRemoveAssets = (confirm) => {
	if (confirm === true) {

		// Hide remove confirmation
		inputModule.hideModalWithId('asset-archive-confirmation');

		// Initialize remove array
		const removeArray = [];

		// Get selected assets
		const assets = document.getElementsByName('asset-selector');
		if (assets.length > 0) {
			for (let i = assets.length - 1; i >= 0; i -= 1) {
				if (assets[i].style.backgroundColor !== '') {

					// Get asset id
					const assetId = assets[i].id.replace('asset-selector-', '');

					// Append to remove array
					removeArray.push(assetId);

					// Remove row from display
					assets[i].parentElement.parentElement.remove();
				}
			}

			// Hide remove button
			document.getElementById('assets-remove-button').enabled = true;
			document.getElementById('assets-remove-button').style.display = 'none';

			// Remove assets from asset dictionary
			const assetArray = [];
			Object.keys(window.assetsGlobalAssetDictionary).forEach((property) => {
				if (Object.prototype.hasOwnProperty.call(window.assetsGlobalAssetDictionary, property)) {
					const displayArray = window.assetsGlobalAssetDictionary[property];
					for (let i = displayArray.length - 1; i >= 0; i -= 1) {
						if (removeArray.indexOf(displayArray[i].id) > -1) {
							displayArray.splice(i, 1);
						}
					}
					Array.prototype.push.apply(assetArray, displayArray);
				}
			});

			// Remove assets from display dictionary
			Object.keys(window.assetsGlobalDisplayDictionary).forEach((property) => {
				if (Object.prototype.hasOwnProperty.call(window.assetsGlobalDisplayDictionary, property)) {
					const displayArray = window.assetsGlobalDisplayDictionary[property];
					for (let i = displayArray.length - 1; i >= 0; i -= 1) {
						if (removeArray.indexOf(displayArray[i].id) > -1) {
							displayArray.splice(i, 1);
						}
					}
				}
			});

			// Remove assets from asset array
			for (let i = window.assetsGlobalAssetArray.length - 1; i >= 0; i -= 1) {
				if (removeArray.indexOf(window.assetsGlobalAssetArray[i].id) > -1) {
					window.assetsGlobalAssetArray.splice(i, 1);
				}
			}

			// Subtract from total
			if (window.assetsGlobalTotalNumber != null) window.assetsGlobalTotalNumber -= removeArray.length;

			// Set asset array to session storage
			try {
				sessionStorage.setItem('user-assets', JSON.stringify(assetArray));
			} catch (e) { }

			// Archive assets
			Parse.Cloud.run('archiveAssetsWithParameters', { assetIDArray: removeArray, platform: 'web' }).then(() => {

				// Update asset display
				updateAssetDisplay();

			}).catch((error) => {
				if (error && error.code === 209) {
					externalUserModule.handleUserLogOut(true);
				} else {

					// Show error
					inputModule.showModalWithId('asset-archive-error-modal');
				}
			});
		} else {

			// Enable remove button
			document.getElementById('assets-remove-button').enabled = true;
		}
	} else {

		// Hide remove confirmation
		inputModule.hideModalWithId('asset-archive-confirmation');

		// Enable remove button
		document.getElementById('assets-remove-button').enabled = true;
	}
};

const exportAssets = async () => {

	// Show status modal
	inputModule.showModalWithId('asset-export-status-modal');

	// Export assets
	await Parse.Cloud.run('exportAssetsForUser', { platform: 'web' });

	// Set timeout for status modal
	setTimeout(() => {

		// Hide status modal
		inputModule.hideModalWithId('asset-export-status-modal');

	}, 6000);
};

const importAssets = () => {
	window.origin_importFile = 'assets';
	appModule.handleRouting('/dashboard/import');
};

const addAsset = () => {
	appModule.handleRouting('/dashboard/asset/new');
};

const editAssetWithId = (id) => {
	appModule.handleRouting(`/dashboard/asset/${id}`);
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on add button
	$('#assets-add-asset').click(() => {
		addAsset();
	});

	// Handle click on load all button
	$('#assets-load-all').click(() => {
		helperModule.displayActionSheet('assets-action-dropdown');
		loadAllAssets();
	});

	// Handle click on export button
	$('#assets-export-assets').click(() => {
		helperModule.displayActionSheet('assets-action-dropdown');
		exportAssets();
	});

	// Handle click on import button
	$('#assets-import-assets').click(() => {
		helperModule.displayActionSheet('assets-action-dropdown');
		importAssets();
	});

	// Handle click on remove button
	$('#assets-remove-button').click(() => {
		removeAssets();
	});

	// Handle click on modal cancel button
	$('#asset-archive-confirmation-cancel').click(() => {
		handleRemoveAssets(false);
	});

	// Handle click on modal remove button
	$('#asset-archive-confirmation-remove').click(() => {
		handleRemoveAssets(true);
	});

	// Handle click on modal confirm button
	$('#asset-archive-error-modal-confirm').click(() => {
		inputModule.hideModalWithId('asset-archive-error-modal');
	});

	// Handle click on asset selector
	$(document).off('click', "[id^='assets-table-load-more-']");
	$(document).on('click', "[id^='assets-table-load-more-']", function () {
		loadMoreAssets($(this).attr('id').replace('assets-table-load-more-', ''));
	});

	// Handle click on asset selector
	$(document).off('click', "[id^='asset-selector-']");
	$(document).on('click', "[id^='asset-selector-']", function (event) {
		if (!isDragging) {
			selectAssetRow(event, $(this).data('asset-id'));
			isDragging = false;
		}
	});

	// Handle click on asset row
	$(document).off('click', "[name='asset-row-element']");
	$(document).on('click', "[name='asset-row-element']", function () {
		if (!isDragging) {
			editAssetWithId($(this).data('asset-id'));
			isDragging = false;
		}
	});

	// Handle click on asset row sort element
	$(document).off('click', "[name='asset-row-sort-element']");
	$(document).on('click', "[name='asset-row-sort-element']", function () {
		if (!isDragging) {
			performAssetSort($(this).data('type-value'), $(this).data('offset'));
			isDragging = false;
		}
	});

	// Handle click on action menu
	$('#assets-action-menu').click(() => {
		helperModule.displayActionSheet('assets-action-dropdown');
	});

	// Handle scroll asset table
	document.addEventListener('scroll', (event) => {
		if (event.target != null && event.target.id != null) {
			if (event.target.id.indexOf('assets-table-view-') > -1) {
				const value = event.target.id.replace('assets-table-view-', '');
				assetsGlobalTableState[value].scroll = $(`#${event.target.id}`).scrollLeft();
			}
		}
	}, true);
};


/**
 * Input Handlers
 */

const createInputHandlers = () => {

	// Handle key up on search
	$('#asset-search-input-large').keyup(function () {
		performAssetSearch(this);
	});

	// Handle change filter
	$('#assets-type-filter').change(function () {
		filterAssetType(this);
	});

	// Handle drag start for table
	$(document).off('mousedown', "[id^='assets-table-view-']");
	$(document).on('mousedown', "[id^='assets-table-view-']", function (e) {

		// Stop propagation
		e.stopPropagation();
		e.preventDefault();

		// Set state
		dragDown = true;
		isDragging = false;
		dragX = e.pageX;
		dragY = e.pageY;
		dragTop = $(this).scrollTop();
		dragLeft = $(this).scrollLeft();
		dragElement = this;

		// Update cursor
		$(this).addClass('grab');
	});

	// Handle drag for table
	$(document).off('mousemove', dragElement);
	$(document).on('mousemove', dragElement, (e) => {
		if (dragDown) {

			// Update state
			const newX = e.pageX;
			const newY = e.pageY;
			isDragging = true;

			// Scroll element
			$(dragElement).scrollTop(dragTop - newY + dragY);
			$(dragElement).scrollLeft(dragLeft - newX + dragX);
		}
	});

	// Handle drag stop for table
	$(document).off('mouseup', dragElement);
	$(document).on('mouseup', dragElement, (e) => {
		if (dragDown) {

			// Stop propagation
			e.stopPropagation();
			e.preventDefault();

			// Update cursor
			$(dragElement).removeClass('grab');

			// Reset state
			dragDown = false;
			dragElement = null;
		}
	});
};


/**
 * State Handlers
 */

exports.handlerDidLoad = async () => {

	// Create action handlers
	createActionHandlers();

	// Create input handlers
	createInputHandlers();

	// Check for visible activity indicator
	inputModule.checkElementVisible('assets-activity-indicator', async () => {

		// Initialize global asset array
		window.assetsGlobalAssetArray = [];

		// Reset variables
		assetsGlobalSelectedFilter = null;
		assetsGlobalSelectedSortDict = null;
		assetsGlobalTableState = null;

		// Fetch configuration for user
		const config = await userModule.handleConfigurationForUser(false);
		assetsGlobalConfiguration = JSON.parse(config);

		// Get available types
		const typeArray = [];
		assetsGlobalAvailableTypes = [];
		assetsGlobalSelectedSortDict = {};
		assetsGlobalTableState = {};
		Object.keys(assetsGlobalConfiguration.display.type_schemas).forEach((property) => {
			if (Object.prototype.hasOwnProperty.call(assetsGlobalConfiguration.display.type_schemas, property)) {
				typeArray.push(property);
				assetsGlobalAvailableTypes.push({
					value: property,
					display: assetsGlobalConfiguration.display.type_schemas[property].display
				});
				assetsGlobalSelectedSortDict[property] = {
					column: 0,
					reverse: false
				};
				assetsGlobalTableState[property] = {
					scroll: 0
				};
			}
		});

		// Set up filter
		setupAssetFilter();

		// Get assets for user
		updateAssetsForUser(true, typeArray, 0);
	});
};

exports.handlerDidAppear = async () => {

	// Update asset display
	if (window.assetsShouldReloadAssets === true) {

		// Initialize dictionaries
		window.assetsGlobalAssetDictionary = {};
		window.assetsGlobalDisplayDictionary = {};

		// Set assets to dictionaries
		for (let i = 0; i < window.assetsGlobalAssetArray.length; i += 1) {
			const asset = window.assetsGlobalAssetArray[i];

			// Set assets
			let typeArray = window.assetsGlobalAssetDictionary[asset.type];
			if (typeArray == null) typeArray = [];
			typeArray.push(asset);
			window.assetsGlobalAssetDictionary[asset.type] = typeArray;
		}

		// Sort type array by largest number of assets
		assetsGlobalAvailableTypes.sort((a, b) => {
			if (window.assetsGlobalAssetDictionary[a.value] == null) window.assetsGlobalAssetDictionary[a.value] = [];
			if (window.assetsGlobalAssetDictionary[b.value] == null) window.assetsGlobalAssetDictionary[b.value] = [];
			return window.assetsGlobalAssetDictionary[b.value].length - window.assetsGlobalAssetDictionary[a.value].length;
		});

		// Perform search and update
		performAssetSearch($('#asset-search-input-large')[0]);

		// Update state
		window.assetsShouldReloadAssets = false;
	}
};
